

import React from 'react';
export const ourCourses=[
    {
        id:'1',
        name:'BPO ',
        icon:'',
        description:`In Business process outsourcing we provide chat support, call support, Email support, Resource management `,
        image:'BPO',
        rating:'4.7',
        url:'/tt',
    
    },
    {
        id:'2',
        name:'Marketing Solutions',
        icon:'',
        description:`Within marketing solutions we incorporate Branding, Content Marketing, PPC Management Services, SEO Services`,
        image:'MarketingSolutions',
        rating:'4.7',
        url:'/tt',
    
    },
    {
        id:'3',
        name:'IT Solutions ',
        icon:'',
        description:`We cover various IT solutions like Custom Software Development, web Development, Mobile App Development, Web Design`,
        image:'ITSolutions',
        rating:'4.7',
        url:'/tt',
    
    },
    {
        id:'4',
        name:'Telecommunications ',
        icon:'',
        description:`DCL supports Telecom equipment, telecom services, and wireless communication in telecommunication`,
        image:'Telecommunications',
        rating:'4.7',
        url:'/tt',
    
    },{
        id:'5',
        name:'Energy & Utilities ',
        icon:'',
        description:`In energy and utilities we contribute large-scale central nuclear and fossil, and Renewable energy providers (growth).`,
        image:'Energy_Utilities',
        rating:'4.7',
        url:'/tt',
    
    },
    {
        id:'6',
        name:'Real Estate ',
        icon:'',
        description:`we equip residential real estate, commercial real estate, and industrial real estate in this real estate industry.`,
        image:'RealEstate',
        rating:'4.7',
        url:'/tt',
    
    },
    {
        id:'7',
        name:'Health & Insurance ',
        icon:'',
        description:`In health and insurance we cover medical devices, equipment, and hospital supplies manufacturers, medical insurance, and managed care.`,
        image:'Health_Insurance',
        rating:'4.7',
        url:'/tt',
    
    },
    {
        id:'8',
        name:'Retail ',
        icon:'',
        description:`Retail is a vast field in which we serve Convenience Stores, Specialty Stores, Department Stores, Supermarkets & Hypermarkets, Discount Stores, Multichannel Stores`,
        image:'retail',
        rating:'4.7',
        url:'/tt',
    
    },
 
]