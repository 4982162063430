import React from 'react'

function PopupClose() {
  return (
    
<svg className='register_popup_close' width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="13" cy="13" r="13" fill=" #83bd1b"/>
<path d="M16.8833 16.898C16.5719 17.2094 16.0671 17.2094 15.7557 16.898L13.2002 14.3425L10.6593 16.8834C10.3156 17.2271 9.75826 17.2271 9.41452 16.8834V16.8834C9.07078 16.5397 9.07078 15.9823 9.41452 15.6386L11.9554 13.0977L9.41452 10.5569C9.10313 10.2455 9.10313 9.74062 9.41452 9.42923V9.42923C9.72591 9.11784 10.2308 9.11784 10.5422 9.42923L13.083 11.9701L15.6385 9.41459C15.9823 9.07084 16.5396 9.07084 16.8833 9.41459V9.41459C17.2271 9.75833 17.2271 10.3156 16.8833 10.6594L14.3278 13.2149L16.8833 15.7704C17.1947 16.0818 17.1947 16.5867 16.8833 16.898V16.898Z" fill="white"/>
</svg>

  )
}

export default PopupClose