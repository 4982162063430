import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Backdrop, Button, Fade, FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Modal, Radio, RadioGroup, Select, TextField,CircularProgress } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import {useSelector,useDispatch} from 'react-redux'
import Cookies from 'js-cookie';
import Arrow from '../../images/iconsSVG/Arrow';
import PopupClose from '../../images/iconsSVG/PopupClose';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

export function Header(props) {
  const dispatch=useDispatch()
 
    const [openRegisterPopup,setOpenRegisterPopup]=useState(false)

    const [url,setUrl]=useState('/')
    const [comingSoon,setComingSoon]=useState(false)
   
    useEffect(()=>{
      window.scrollTo(0,0)
      
      window.onscroll = function () {      
        if (document.documentElement.scrollTop > 630) {
          $("#scrollTopId").css({
            transform: "scale(1)",
          });
        } else {
          $("#scrollTopId").css({
            transform: "scale(0)",
          });
        }
      };

      setUrl(window.location.pathname)

    

    },[])
      
      function handleToggle() {
        $("#nav_con_id").toggleClass("nav_view");
      }
      
      
      const handleNac = (num) => {
        handleToggle()
        setTimeout(() => {
          window.scrollTo(0, window.pageYOffset - 100);
        }, 1000);
      };
  function scrollToTop(){
    window.scrollTo(0,0)
  }
  return (
    <>
    <section className="container-fluid website_header">
<div className="header_inner">
    <a href='/'><img alt='dclindia Coimbatore' src={require('../../images/websitenew/data_communication.jpg')} /></a>    
    
    <ul id="nav_con_id" className="nav_con">
        {
          
            <>
             <li className="nav_link" onClick={()=>handleNac(1)}><a href="#about">About Us</a></li>
             <li className="nav_link" onClick={()=>handleNac(5)}><a href="#why_dcl">Why DCL</a></li>
             <li className="nav_link" onClick={()=>handleNac(3)}><a href="#faq">	FAQ</a></li>
             <li className="nav_link" onClick={()=>setComingSoon(true)} style={{cursor:'pointer',fontSize:'16px'}}>	Leads Pond</li>

            </>
          
        }
       
        <li className="nav_link_btn_green" onClick={()=>setOpenRegisterPopup(true)}><button className='ezskill_getstart'>Contact Us</button></li>
    </ul>
    <button className="toggle_button" onClick={handleToggle}> <i className="fa fa-bars nav_fa_bars"></i>
        <i className="fa fa-times nav_fa_times" aria-hidden="true"></i>
    </button>
</div>

</section>
 
<RegisterPopup openRegisterPopup={openRegisterPopup} setOpenRegisterPopup={setOpenRegisterPopup}/>
<ComingSoon comingSoon={comingSoon} setComingSoon={setComingSoon}/>
<div id='scrollTopId' onClick={scrollToTop}>
<Arrow/>
</div>
    </>

  )
}




export const RegisterPopup=(props)=>{
  const classes = useStyles();

  const [successMsg,setSuccessMsg] = useState();
  const [countryCode, setCountryCode] = useState();
  const [fields,setFields] = useState({});
  const [errors,setErrors] = useState({});
  const [loader,setLoader] = useState(false);

  const [packagePopup,setPackagePopup]= useState(false);



  const reduxValue=useSelector(state=>state)
  const dispatch=useDispatch()




  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]:e.target.value })
};
const setValuesPhone = (value, country) => {
  setFields({...fields,'phone':value})
  setCountryCode(country.dialCode);
};
function register(){
  if(validate()){
    console.log("ressss11")
    setLoader(true)
    axios.post('https://formsubmit.co/ajax/dclindia@datacommunicationsleader.com',{
      "name": fields.name,
      "email": fields.email,
      "phoneno": countryCode=='91'?fields.phone.slice(2):fields.phone,
      "price_plan": fields.interested_in,
      "login_type": fields.occupation,
      "comments": fields.comments,
    })
    .then(res=>{
      console.log("ressss",res)
      setSuccessMsg(res.data.message)
      dispatch({type:'TOASTER',payload:res.data.message})
      setLoader(false)
 
        props.setOpenRegisterPopup(false)
      
    
    })
    .catch(err=>{
      setSuccessMsg("Something wrong...Please try again")
    })
  }
}
function validate(){
    let errorss={}
    let formIsValid=true
    if(!fields['name']){
      errorss['name']="*Enter the name"
      formIsValid = false;
    }
    if(!fields['phone']){
      errorss['phone']="*Enter the phone number"
      formIsValid = false;
    }
    else{
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(fields['phone'])) {
        formIsValid = false;
        errorss['phone']= '*Please enter only number.';
        fields['phone'] = null;
      }
    }
   
    if(!fields['email'] || !/\S+@\S+\.\S+/.test(fields['email'])){
      errorss['email']="*Enter the email"
      formIsValid = false;
    }
    
    if(!fields['comments']){
      errorss['comments']="*Enter the comments"
      formIsValid = false;
    }
    setErrors(errorss)
    return formIsValid
}

  return(
    <>
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={props.openRegisterPopup}
      onClose={()=>props.setOpenRegisterPopup(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.openRegisterPopup}>
        <div 
      
        className='register_popup'
        >
            <div onClick={()=>props.setOpenRegisterPopup(false)}>
            <PopupClose />
            </div>
      
          <div className='register_popup_inner_right'>
          <h1>Contact Us To Get More Information</h1> 


                <input type="text" placeholder="Full Name" name="name" onChange={handleChange}/>
                <p style={{color:'red',margin:'0'}}>{errors.name}</p>
            <PhoneInput
                          className="col-ls-12"
                          country="in"
                          enableSearch
                          disableSearchIcon
                          placeholder="Phone Number"
                          inputStyle={{
                            width: '100%',
                            padding: '22px 42px',
                            
                          }}
                          
                          preferredCountries={['in', 'us', 'sg']}
                          priority={{ in: 0, us: 1, sg: 2 }}
                          onChange={(value, country) =>
                            setValuesPhone(value, country)
                          }
                        />
                         <p style={{color:'red',margin:'0'}}>{errors.phone}</p>

            <input type="email" name="email" placeholder="Email Address" onChange={handleChange}  />
            <p style={{color:'red',margin:'0'}}>{errors.email}</p>
            <textarea placeholder='Your Comments' name="comments" onChange={handleChange} />
            <p style={{color:'red',margin:'0'}}>{errors.comments}</p>
          
               
            <Button variant="contained" onClick={register}>
              {loader?<CircularProgress color="success" style={{width:'25px',height:'25px'}}/>:'Submit'}
            </Button>
            {successMsg&&<p style={{color:'green'}}>{successMsg}</p>}
          </div>

        </div>
      </Fade>
    </Modal>
    
   
    </>
  )
}




export const ComingSoon=(props)=>{
  const classes = useStyles();

  const [successMsg,setSuccessMsg] = useState();
  const [countryCode, setCountryCode] = useState();
  const [fields,setFields] = useState({});
  const [errors,setErrors] = useState({});
  const [loader,setLoader] = useState(false);

  const [packagePopup,setPackagePopup]= useState(false);

  return(
    <>
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={props.comingSoon}
      onClose={()=>props.setComingSoon(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.comingSoon}>
        <div 
      
        className='register_popup'
        >
            <div onClick={()=>props.setComingSoon(false)}>
            <PopupClose />
            </div>
      
          <div className='register_popup_inner_right'>
             <h1 style={{fontSize:'60px'}}>Coming Soon...</h1>
         </div>

        </div>
      </Fade>
    </Modal>
    
   
    </>
  )
}