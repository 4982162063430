import React,{useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css';
import './App.css';

import { BrowserRouter as Router , Route } from 'react-router-dom';
import { withRouter } from 'react-router'
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Notfound from './components/website/notfound';
import Home from './components/website/pages/home';
import { Header } from './components/website/header';
import Footer from './components/website/footer';
import './components/website/styles.scss'

function App(props) {
	 useEffect(()=>{
	},[])
	

  return (
    <div className="App">
      <div id='website_container'>
       <Header/>
      <div id='website_content'>
     
	 <Switch>
	
		<Route exact path='/' component={Home} />
    <Route exact path='/**'><Notfound/></Route>
	 </Switch>
  
   </div>
   <Footer/>
   </div>
    </div>
  );
}

export default withRouter(App);


function PageNotFound(){
	return(
<div style={{width:'100vw',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
<h1>Page Not Found</h1>
				</div>
	)
}
