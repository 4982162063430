const Filter =(state={},action)=>{
    switch(action.type){
        // case "USER_DETAILS":
        //     return {
        //         ...state,
        //         userdetails:action.userdetails
        //     }
    case "USER_BREADCRUMPS":
            return {
                ...state,
                breadcrump:action.breadcrump
            }
    //  case "MESSAGE_COUNT":
    //    return {
    //        ...state,
    //        message_count:action.message_count
    //    }
    
    case "TOASTER":
        return {
            ...state,
            toaster_message:action.payload
        }
    case "TOASTER_OTHER":
        return {
            ...state,
            toaster_other_message:action.payload,
            toaster_type:action.toaster_type,
        }
    }
    }
    export default Filter