import React , { useEffect,useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery'
import Arrow from '../../../images/iconsSVG/Arrow';
import Star from '../../../images/iconsSVG/Star';
import IndividualPlanIcon from '../../../images/iconsSVG/Individual';
import PackagePlanIcon from '../../../images/iconsSVG/PackagePlan';
import CustomizePlanIcon from '../../../images/iconsSVG/CustomizePlan';
import {Link} from 'react-router-dom'
import {RegisterPopup} from '../header'
import { ourCourses } from './data/our_courses';
import { Backdrop, Fade, makeStyles, Modal } from '@material-ui/core';
import CustomerSupport from '../../../images/iconsSVG/New/CustomerSupport';
import Hours from '../../../images/iconsSVG/New/Hours';
import ResponseImmediately from '../../../images/iconsSVG/New/ResponseImmediately';
import CustomerPride from '../../../images/iconsSVG/New/CustomerPride';
import SalesImprovement from '../../../images/iconsSVG/New/SalesImprovement';
import PriortizeAccordingly from '../../../images/iconsSVG/New/PriortizeAccordingly';
import AUTOATTENDANT from '../../../images/iconsSVG/New/AUTOATTENDANT';
import CALLROUTING from './../../../images/iconsSVG/New/CALLROUTING';
import CALLBACK from './../../../images/iconsSVG/New/CALLBACK';
import ANALYTICALVIEW from './../../../images/iconsSVG/New/ANALYTICALVIEW';
import CALLMONITORING from './../../../images/iconsSVG/New/CALLMONITORING';
import HIGHENDINTEGRATIONS from './../../../images/iconsSVG/New/HIGHENDINTEGRATIONS';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor:theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));


const Home = () => {
    const classes = useStyles();

    const [ratingBox,setRatingBox]=useState(1)
    const [ourCourseTab,setOurCourseTab]=useState('1')

    const [individualActive,setIndividualActive]= useState()
    const [packageActive,setPackageActive]= useState()
    const [customizedActive,setCustomizedActive]= useState()
    const [customizeCount,setCustomizeCount]=useState(0)

    const [openRegisterPopup,setOpenRegisterPopup]=useState(false)

    const [highlightPopup,setHighlightPopup]=useState(false)


  

    useEffect(()=>{
            const sectionAll = document.querySelectorAll("section");
            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                   
                  if (entry.isIntersecting) {
                    
                    entry.target.classList.add("scroll_ani");
                    if(entry.target.classList.contains('highlights_our_course')){
                        entry.target.classList.add("animated_highlights_our_course");
                    }
                    if(entry.target.classList.contains('tools_you_master')){
                        entry.target.classList.add("tools_you_master_ani");
                    }
                  }
                });
              },
              {
                rootMargin: "-100px",
              }
            );
            sectionAll.forEach((section) => {
              observer.observe(section);
            });
        $('.customizeCheck').prop('disabled',true)
        $('.individualCheck').prop('disabled',true)
        $('#batch_pricing_box2').css('filter','drop-shadow(0px 0px 44px rgba(96, 96, 96, 0.25))')  

        document.getElementById('website_courses_con').onscroll=()=>{
        }


       

    },[])

    const certificate_settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
      };
    const rating_settings={
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnHover: false,
        afterChange: function(index) {
            let value=index===5?0:index+1;
            setRatingBox(value);
          },
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                slidesToShow: 1,
                afterChange: function(index) {
                    setRatingBox(index)
                  },
              },
            },
           
          ],
    }

    function handleCourseTab(id){
        $('.list_courses').removeClass('list_courses_active');
        $('#list_courses'+id).addClass('list_courses_active')

        let breakpoints=[];
        let count=0
        for(let i=0;i<10;i++){
            breakpoints.push(count)
            count=count+190
        }
        const minusVal=window.innerWidth>767?50:200
        $('#website_courses_con').scrollTop(breakpoints[+id-1]-minusVal)

        $('.website_courses').removeClass('website_courses_active');
        $('#website_courses'+id).addClass('website_courses_active')
        
    }
 
    
    function learnMore(id){
        $('.website_courses>div span').css('display',"none !important")
        $('#website_courses'+id+">div span").css('display',"block")
    }

    
    function MoreTools(){
        if(document.getElementById('master_tools_con').style.maxHeight!="3500px"){
            document.getElementById('more_tools').innerHTML='View Less'
            document.getElementById('master_tools_con').style.maxHeight="3500px"
        }
        else{
            document.getElementById('more_tools').innerHTML='More Tools'
            document.getElementById('master_tools_con').style.maxHeight="300px"
        }    
    }

    function ourCourseAcc(id){
        if(document.getElementById('courseacc'+id)?.classList.contains('show')){
            $('#courseacc'+id).removeClass('show')
       }else{  
        $(".courseacchead>div").addClass('collapsed')
        $('.courseacc_collapse').removeClass('show')
        $('#courseacc'+id).addClass('show')
        $("#courseacchead"+id+">div").removeClass('collapsed') 
       }
      
    }
   
    return(
        <div id="home">






{/* <!-- Banner Section  --> */}

<section className="banner_sec">  
    
<div className="container">

 <div className="row">
 <div className="col-md-6 text-center">

<img alt="dclindia Coimbatore" className='website_banner_standing_girl' src={require('../../../images/websitenew/smiling_girl.png')}  />


</div>
           <div className="col-md-6 centered_content website_left_inner_banner">
                {/* <h1>DCL India - carved to funnel your leads to business</h1> */}
                <p> <span>Top-notch voice/chat support solutions</span></p>

                <h1>
                DCL India -Carved to funnel your leads to business
                </h1> 

                {/* <p style={{margin:'15px 0'}}> 
                Innovative IT solutions
                </p> */}

                <div style={{display: "flex", marginTop: "20px"}}>
                    <button onClick={()=>setOpenRegisterPopup(true)} className="btn btn-outline-primary my-2 my-sm-0 ml-3 active_btn apply_now_btn" type="submit"> Contact Us &nbsp; <Arrow/> </button> &nbsp;&nbsp;&nbsp;

                </div>
            

            </div>

         
 </div>
</div>

</section>

{/* Counter Section */}

<div className="counter_area">


<div className="container" style={{maxWidth: "1120px !important"}}>

<div className="row counter_section" style={{display:'flex',flexWrap:'wrap'}}>
<span className='mbl_100'> 100%  </span>
    <div className="col-md-3 col-sm-3 col-6 centered_content text-center">
    <span className='desk_100'> 100%  </span>
       Authentic call service
    </div>

    <div className="col-md-3 col-sm-3 col-6 centered_content text-center">
       
        	Assured chat support
    </div>

    <div className="col-md-3 col-sm-3 col-6 centered_content text-center">
      
        	Profit-earning IT solutions
    </div>

    <div className="col-md-3 col-sm-3 col-6 centered_content text-center">
       
        Strategic marketing ways
    </div>

 </div>

</div>
</div>

<section  id ='why_dcl' className="methodology_sec section_spaces">

    <div className="container">

        <div className="text-center">

            
            <h2 className="main_title"> OUR WORKING STYLE</h2>
    
           

        </div>

        <div className="row mt-5" style={{display:'flex',flexWrap:'wrap'}}>

            <div className="col-md-4 col-xs-6">
                <CustomerSupport/>

                <p className="sub_title"> Call/chat customer support </p>

                <p className="description"> 
                A customer desires several matters, firstly that their name could be dealt with efficaciously; secondly, that something it's far that has been agreed will be carried out and thirdly that they will be spoken to in a well-mannered and courteous manner. All our workers ought to make certain, is that they supply each of this stuff to ensure that the client will be satisfied.
                </p>

            </div>

            <div className="col-md-4 col-xs-6">
             <Hours/>

                <p className="sub_title"> 24*7 support </p>

                <p className="description"> 
                With our call center software program, you could fulfill all your customers' requests professionally. Our services work 24/7, which makes it brilliant to compliment the image of your business retailers. it is the primary touchline for each time customers have a professional call center like us.
                </p>

            </div>

            <div className="col-md-4 col-xs-6">
             <ResponseImmediately/>

            <p className="sub_title"> Response immediately  </p>

            <p className="description"> 
            Having an immediate response is the most crucial factor in achieving customer satisfaction. 40% of clients might prefer professional assistance to remedy their troubles quickly. Our call center service can mechanically direct and reply to a call in an expert manner. It is designed to serve customer support an awful lot successfully.
            </p>

            </div>

            <div className="col-md-4 col-xs-6">
                <CustomerPride/>

                <p className="sub_title"> Consumer pride  </p>

                <p className="description"> 
                Consumer offerings require a wonderful deal of time determination. Incorporating our BPO service software program permits you to cut the difficulty by using offering a full-time provider. We will manipulate each question, like ticket help, sales, appointment putting, and technical problems. ultimately, you've got helped by our call marketers to enhance your productivity at work.
                </p>

            </div>
            

       

            <div className="col-md-4 col-xs-6">
                <SalesImprovement/>

                <p className="sub_title">Sales improvement </p>

                <p className="description"> 
                With our call center software program, you'll acquire beneficial features that work 24/7. You might not be able to build about integrating unanswered calls all through the service, that is often the case with many organizations. Our call center program functions to direct calls and measure call metrics which improves your sales.
                </p>

            </div>

           


            <div className="col-md-4 col-xs-6">

                <ResponseImmediately />
                <p className="sub_title"> Answer immediately  </p>

                <p className="description"> 
                90% of voice calls will never return. Because of this, the risk of getting important queries replied to will be lower. Having an available 24/7 patron call center service provider like us can boom your income deals drastically. We can also make an outstanding marketing tool because it responds to all inbound sales queries.
                </p> 

                


            </div>

            <div className="col-md-4 col-xs-6">
                <PriortizeAccordingly/>

<p className="sub_title"> Prioritize accordingly  </p>

<p className="description"> Prioritizing calls is crucial for an enterprise to broaden customer loyalty and boom the purchaser experience. Our call center service program provides these records with the intention to make logical order. At the equal time, the service will ensure different calls are inside the queue so that you may not lose any essential calls in the procedure.</p> 

</div>
            

        </div>

        


    </div>
    
</section>

<section id='about' className="highlights_our_course section_spaces">
 <div className="container">
    <div className='row'>
    <div className='col-lg-5'>
            <div className='highlights_our_course_inner_right'>
            <h2>ABOUT US</h2> 
            <p>Data communications leader India (DCL) is a multiproduct client call/chat support program organization centered on customer service, advertising, and IT solutions segments. DCL by its roots believes in being empathetic to clients and supplying services and products that deliver an incremental business outcome by assisting in the means of voice/chat sales support.</p>
            </div>           
        </div> 
        <div className='col-lg-7'>
            <div className='highlights_our_course_inner_left'>
                
                <div className='hos_small_box with_heading hsb2'>
                    <div>
                    <p> Real Estate</p>
                    </div>                  
                </div>
                <div className='hos_small_box hsb3'>
                    <div>
                    <p>Energy & Utilities</p>
                    </div>                  
                </div>
                <div className='hos_big_box hsb4'>
                    <div>
                    <p> Tele - communications</p>
                    </div>                  
                </div>
                <div className='hos_big_box hsb5'>
                    <div>
                    <p>Health & Insurance </p>
                    </div>                  
                </div>
                <div className='hos_small_box hsb6'>
                    <div>
                    <p>Retail</p>
                    </div>                  
                </div>
                <div className='hos_small_box hsb7'>
                    <div>
                    <p>IT Solutions </p>
                    </div>                  
                </div>
                
            </div>
        </div>
          
    </div>    
     </div>
 </section>



<section id='case_study' className="projects_sec section_spaces">

<div className="container">

  <div className="text-center">

    <h2 className="main_title"> OUR CALL CENTER ADVANTAGES </h2>

    

  </div>

  <div className="row" style={{display:"flex",flexWrap:'wrap'}}>

     <div className="col-md-6 mt-5">

        <div className="row">

            <div className="col-md-6 col-xs-12">
                <img alt='dclindia Coimbatore' className='thumb_img' src={require('../../../images/websitenew/OUR-CALL-CENTER-ADVANTAGES/AUTO-ATTENDANT.jpg')} />
               <div className="image_icon">
                <AUTOATTENDANT/>
                </div>
            </div>

            <div className="col-md-6 col-xs-12 centered_content ">
                <p className="sub_title">AUTO-ATTENDANT </p>

                <p className="description"> 
                Our auto attendant software greets and forwards callers primarily based on which menu alternative they pick out. keep time, reduce expenses, and appear more expert while you set up your digital call attendant today with us.
                </p> 
            </div>

        </div>

     </div>

     <div className="col-md-6 mt-5">

        <div className="row">

            <div className="col-md-6 col-xs-12">
                <img alt='dclindia Coimbatore' className='thumb_img' src={require('../../../images/websitenew/OUR-CALL-CENTER-ADVANTAGES/CALL-ROUTING.jpg')} />
                <div className="image_icon">
                    <CALLROUTING/>
                </div>
            </div>

            <div className="col-md-6 col-xs-12 centered_content ">
                <p className="sub_title"> CALL ROUTING </p>

                <p className="description"> 
                Superior Routing from DCL gives a suite of intelligent routing options for steering your callers to the right character at the right time. suit callers to sellers primarily based on common abilities like spoken language and prioritize your most vital callers to make certain a remarkable caller experience that leaves them feeling loved.
                </p> 
            </div>

        </div>

     </div>

     <div className="col-md-6 mt-5">

        <div className="row">

            <div className="col-md-6 col-xs-12">
               <img alt='dclindia Coimbatore' className='thumb_img' src={require('../../../images/websitenew/OUR-CALL-CENTER-ADVANTAGES/QUEUE-CALLBACK.jpg')} />
               <div className="image_icon">
                <CALLBACK/>
                </div>
            </div>

            <div className="col-md-6 col-xs-12 centered_content ">
                <p className="sub_title">QUEUE CALLBACK</p>

                <p className="description"> 
                improve customer stories and decrease name abandonment by way of permitting callers to save their place within the queue and choose a call lower back while an agent is available. Attempt DCL’s call-center service program and have a hazard-loose today!
                </p> 
            </div>

        </div>

     </div>

     <div className="col-md-6 mt-5">

        <div className="row">

            <div className="col-md-6 col-xs-12">
                <img alt='dclindia Coimbatore' className='thumb_img' src={require('../../../images/websitenew/OUR-CALL-CENTER-ADVANTAGES/analytical.jpg')} />
                <div className="image_icon">
                <img alt='dclindia Coimbatore' src={require('../../../images/websitenew/OUR-CALL-CENTER-ADVANTAGES/analytical_view.jpg')} />
                </div>
            </div>

            <div className="col-md-6 col-xs-12 centered_content ">
                <p className="sub_title">ANALYTICAL VIEW </p>

                <p className="description"> 
                Expertise in how your team is performing and whilst modifications are wanted can be a mission. DCL makes it simple to get the insights you want to live in advance of crucial provider-stage metrics and proactively manipulate your touch middle and groups. 
                </p> 
            </div>

        </div>

     </div>
     
     <div className="col-md-6 mt-5">

<div className="row">

    <div className="col-md-6 col-xs-12">
        <img alt='dclindia Coimbatore' className='thumb_img' src={require('../../../images/websitenew/OUR-CALL-CENTER-ADVANTAGES/CALL-MONITORING.jpg')} />
        <div className="image_icon">
            <CALLMONITORING/>
        </div>
    </div>

    <div className="col-md-6 col-xs-12 centered_content ">
        <p className="sub_title"> CALL MONITORING</p>

        <p className="description"> 
        Call monitoring software program facilitates you to coach your dealers and improve your crew's performances over the years with call monitoring, call barge and whisper, call satisfactory scoring, call tagging, superior analytics, and more.
        </p> 
    </div>

</div>

</div>
<div className="col-md-6 mt-5">

<div className="row">

    <div className="col-md-6 col-xs-12">
        <img alt='dclindia Coimbatore' className='thumb_img' src={require('../../../images/websitenew/OUR-CALL-CENTER-ADVANTAGES/HIGH-END-INTEGRATIONS.jpg')} />
        <div className="image_icon">
            <HIGHENDINTEGRATIONS/>
        </div>
    </div>

    <div className="col-md-6 col-xs-12 centered_content ">
        <p className="sub_title"> HIGH-END INTEGRATIONS </p>

        <p className="description"> 
        Streamline your workflow and stay connected with DCL's integrations.
Seamlessly join popular commercial enterprise equipment in only some clicks!

        </p> 
    </div>

</div>

</div>

  </div>

</div>

</section>


<section id='syllabus' className="website_our_courses section_spaces">

<div className="container">
    <h2>OUR SERVICES</h2>
    <p>At DCL, our software modernization offerings assist you to attain agility for an increasing number of virtual internationals by voice/chat support. We combine an aggregate of accelerators, structures, and strategic companions to modernize enterprise applications. The result: An enterprise that’s prepared for whatever the new ordinary has in keep for you.
    </p>
   <div id='desk_website_our_courses' className='row' >
          
           <div className='col-lg-8'>
              
             <div id='website_courses_con' className='website_courses_con'>
                 {
                     ourCourses.map((course)=>(
                       <div id={"website_courses"+course.id} className='website_courses' onClick={()=>handleCourseTab(course.id)}>
                       <img alt='dclindia Coimbatore' src={require(`../../../images/websitenew/OUR-SERVICES/${course.image}.jpg`)}/>
                       <div>
                           <div> <h4>{course.id}. {course.name} </h4><div><Star/>&nbsp;({course.rating})</div></div>
                         <p dangerouslySetInnerHTML={{ __html: course.description }}>
                         
                         </p>
                        
                         
                         <br/>
                         <div style={{justifyContent:'right'}}>
                             
                         </div> 
                       </div>
                       </div>
                     ))
                 }
                 
                           </div>         
   </div>
   <div className='col-lg-4'>
               <div className='list_courses_con' style={{marginTop:'23px'}}>
                   {
                       ourCourses.map((courses,index)=>(
                           <div key={index} id={"list_courses"+courses.id} className={courses.id==='1'?'list_courses list_courses_active':'list_courses'} onClick={()=>handleCourseTab(courses.id)}>
                               <div className='lc_side_number' >{courses.id}</div>
                               <p>{courses.name}</p>
                           </div>
                       ))
                   }
                 

               </div>
           </div>

   </div>

   <div id='mbl_website_our_courses'>
   <div id="courseacc_sec">
   <div className="accordion" id="courseacc">
       {
           ourCourses.map((course)=>(
               <div className="card" 
               onClick={()=>ourCourseAcc(course.id)}   
               key={course.id}
               >
               <div className="card-header courseacchead" id={"courseacchead"+course.id}>
                   <div className="btn btn-header-link collapsed" data-target={"#faq"+course.id}
                   aria-expanded="true" aria-controls={"faq"+course.id}> {course.id}. {course.name}</div>
               </div>

               <div id={"courseacc"+course.id} className="collapse courseacc_collapse" aria-labelledby={"faqhead"+course.id} data-parent="#faq">
                   <div className="card-body" >
                       <div className='course_card'>
                       <img alt='dclindia Coimbatore' src={require(`../../../images/websitenew/OUR-SERVICES/${course.image}.jpg`)}/>
                       <p dangerouslySetInnerHTML={{ __html: course.description }}>                       
                       </p>                    
                       </div>                   
                   </div>
               </div>
           </div>
           ))
       }
                            
                         </div>
                         </div>
                         </div>

    </div>
</section>




    
    <section className="rating_feedback section_spaces">

<div className="container">
   <h2>Feedback</h2>
   <div className='website_feedback_container'>
   <Slider {...rating_settings}>
       <div>
       <div id="website_feedback0" className={`website_feedback ${ratingBox===0?'website_feedback_active':''}`}>
           <p>They are best in rendering some quality leads . </p>
           
           <div>
               
               <div>
                   <h5>- Terrie Jones</h5>
               </div>
           </div>
       </div>
       </div>
       <div>
       <div id="website_feedback1" className={`website_feedback ${ratingBox===1?'website_feedback_active':''}`}>
           <p>Their Funnel plans to filter out the right lead for the business. Is amazing, I recommend them 100% </p>
           
           <div>

               <div>
                   <h5>- Dana Perez </h5>
               </div>
           </div>
       </div>
       </div>
       <div>
       <div id="website_feedback2" className={`website_feedback ${ratingBox===2?'website_feedback_active':''}`}>
           <p>Best Team to handle Facebook chats and close sales.</p>
           
           <div>
             
               <div>
                   <h5>- David   </h5>
               </div>
           </div>
       </div>
       </div>
       <div>
       <div id="website_feedback3" className={`website_feedback ${ratingBox===3?'website_feedback_active':''}`}>
           <p>They are best in rendering some quality leads . </p>
           
           <div>
               
               <div>
                   <h5>- Terrie Jones</h5>
               </div>
           </div>
       </div>
       </div>
        <div>
        <div id="website_feedback4" className={`website_feedback ${ratingBox===4?'website_feedback_active':''}`}>
           <p>Their Funnel plans to filter out the right lead for the business. Is amazing, I recommend them 100% </p>
           
           <div>

               <div>
                   <h5>- Dana Perez </h5>
               </div>
           </div>
       </div>
       </div>
       <div>
       <div id="website_feedback5" className={`website_feedback ${ratingBox===5?'website_feedback_active':''}`}>
           <p>Best Team to handle Facebook chats and close sales.</p>
           
           <div>
             
               <div>
                   <h5>- David   </h5>
               </div>
           </div>
       </div>
       </div>
    
   </Slider>
     
   </div>
    </div>
</section>



{/* <!-- Q & A Section  --> */}

<section className="qa_sec section_spaces mt-5" id="faq">

    <div className="container">
        
        <div className="text-center mb-5">

            <h2 className="main_title"> Frequently Asked Question</h2>

            <p className="description">
            Your questions answered on our approach, our team, our values, our process, costs, timeline and more.
            </p>
        

        </div>


          <div id="faq_sec">
            <div className="container">
          <div className="accordion" id="faq">
                              <div className="card">
                                  <div className="card-header" id="faqhead1">
                                      <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq1"
                                      aria-expanded="true" aria-controls="faq1"> 1. Do the call center employees undergo any kind of training?</div>
                                  </div>
          
                                  <div id="faq1" className="collapse" aria-labelledby="faqhead1" data-parent="#faq">
                                      <div className="card-body">
                                      Our employees are pre-trained at handling calls and different situations. They are provided a fresher course before they start working on a new project. Your website, product and service guides, and previous mail correspondence with your clients help them understand your business better. Read more about call center training at Flatworld.
                                      </div>
                                  </div>
                              </div>
                              <div className="card">
                                  <div className="card-header" id="faqhead2">
                                      <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq2"
                                      aria-expanded="true" aria-controls="faq2"> 2. How do you ensure that my data is secure?
                                      </div>
                                  </div>
          
                                  <div id="faq2" className="collapse" aria-labelledby="faqhead2" data-parent="#faq">
                                      <div className="card-body">
                                      We never compromise on your data and other confidential information. Our employees are made to sign a non-disclosure agreement before they start working  with Flatworld, and any violations are severely dealt with. You can be assured of your business' safety with us. Read more about call center security at Flatworld.
                                      </div>
                                  </div>
                              </div>
                              <div className="card">
                                  <div className="card-header" id="faqhead3">
                                      <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq3"
                                      aria-expanded="true" aria-controls="faq3"> 3. Do you support any Live Chat software?
                                      </div>
                                  </div>
          
                                  <div id="faq3" className="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                                      <div className="card-body">
                                      Yes. We do support live chat software, and our agents are trained at using them
                                      </div>
                                  </div>
                              </div>
                              <div className="card">
                                <div className="card-header" id="faqhead4">
                                    <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq4"
                                    aria-expanded="true" aria-controls="faq4"> 4. Are agents able to multitask in their free time?
                                    </div>
                                </div>
        
                                <div id="faq4" className="collapse" aria-labelledby="faqhead4" data-parent="#faq">
                                    <div className="card-body">
                                    Our agents are not encouraged to provide supplementary services or multitask, as this will shift their focus and affect the quality of our services.<br/> Also, we are billed on timely basis, so multitasking will be unfair to the client.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="faqhead5">
                                    <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq5"
                                    aria-expanded="true" aria-controls="faq5"> 5. I would like to outsource my requirements to you, how do I start?
                                    </div>
                                </div>
        
                                <div id="faq5" className="collapse" aria-labelledby="faqhead5" data-parent="#faq">
                                    <div className="card-body">
                                    This is a simple process. Fill up the sign-up form or talk to our representatives. We will then draft a customized service contract for you. Once you receive the contract, agree to it, and email us the signed copy along with the payment, we will start training our staff for 1-2 weeks as per your business requirement. Thereafter, we will start replying to all emails, calls, chats from your customers.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="faqhead6">
                                    <div className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq6"
                                    aria-expanded="true" aria-controls="faq6"> 6. What are the working hours?
                                    </div>
                                </div>
        
                                <div id="faq6" className="collapse" aria-labelledby="faqhead6" data-parent="#faq">
                                    <div className="card-body">
                                    We provide services 24 hours a day, 365 days a year.                                
                                    </div>
                                </div>
                            </div>
                          </div>
              </div>
            </div>

    </div>

</section>





<RegisterPopup openRegisterPopup={openRegisterPopup} setOpenRegisterPopup={setOpenRegisterPopup}/>


        </div>
    )
}

export default Home


