import React from 'react'

function CALLROUTING() {
  return (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 512 512" style={{enableBackground:'new 0 0 512 512'}} xmlSpace="preserve">
<path style={{fill:'#EFEFEF'}} d="M387,50v412c0,22.09-17.91,40-40,40H165c-22.09,0-40-17.91-40-40V50c0-22.09,17.91-40,40-40h182
   C369.09,10,387,27.91,387,50z"/>
<g>
   <rect x="296" y="50" style={{fill:'#3BBF9A'}} width="91" height="372"/>
   <rect x="176" y="369.5" style={{fill:'#3BBF9A'}} width="40" height="52.5"/>
</g>
<rect x="296" y="50" style={{fill:'#EFEFEF'}} width="91" height="39.5"/>
<rect x="125" y="89.5" style={{fill:'#3BBF9A'}} width="91" height="200"/>
<polygon style={{fill:'#FAB421'}} points="296,169.5 296,209.5 387,209.5 387,289.5 296,289.5 296,422 216,422 216,369.5 125,369.5 
   125,289.5 216,289.5 216,50 296,50 296,89.5 387,89.5 387,169.5 "/>
<g>
   <rect x="296" y="169.75" style={{fill:'#EFEFEF'}} width="45.5" height="39.5"/>
   <rect x="125" y="209.5" style={{fill:'#EFEFEF'}} width="51" height="80"/>
   <rect x="125" y="129.5" style={{fill:'#EFEFEF'}} width="51" height="40"/>
</g>
<rect x="125" y="50" style={{fill:'#CCC3BB'}} width="91" height="39.5"/>
<rect x="296" y="329.5" style={{fill:'#EFEFEF'}} width="51" height="60"/>
<path d="M256,472c2.63,0,5.21-1.07,7.069-2.931C264.93,467.21,266,464.63,266,462s-1.07-5.21-2.931-7.07
   C261.21,453.07,258.63,452,256,452s-5.21,1.069-7.07,2.93S246,459.37,246,462s1.069,5.21,2.93,7.069
   C250.79,470.93,253.37,472,256,472z"/>
<path d="M347,0H165c-27.57,0-50,22.43-50,50v412c0,27.57,22.43,50,50,50h182c27.57,0,50-22.43,50-50V50C397,22.43,374.57,0,347,0z
    M165,20h182c13.036,0,24.152,8.361,28.28,20H136.72C140.848,28.361,151.964,20,165,20z M135,60h71v19.5h-71V60z M135,139.5h31v20
   h-31V139.5z M135,179.5h41c5.522,0,10-4.478,10-10v-40c0-5.522-4.478-10-10-10h-41v-20h71v180h-20v-70c0-5.522-4.478-10-10-10h-41
   V179.5z M135,219.5h31v60h-31V219.5z M135,379.5h31V412h-31V379.5z M377,462c0,16.542-13.458,30-30,30H165
   c-16.542,0-30-13.458-30-30v-30h242V462z M186,412v-32.5h20V412H186z M377,412h-71v-12.5h41c5.522,0,10-4.478,10-10v-60
   c0-5.522-4.478-10-10-10h-41v-20h71V412z M306,379.5v-40h31v40H306z M377,199.5h-25.5v-20H377V199.5z M377,119.5h-10
   c-5.522,0-10,4.478-10,10s4.478,10,10,10h10v20h-81c-5.522,0-10,4.478-10,10v40c0,5.522,4.478,10,10,10h81v60h-81
   c-5.522,0-10,4.478-10,10V412h-60v-42.5c0-5.522-4.478-10-10-10h-81v-20h10c5.522,0,10-4.478,10-10s-4.478-10-10-10h-10v-20h81
   c5.522,0,10-4.478,10-10V60h60v29.5c0,5.522,4.478,10,10,10h81V119.5z M331.5,179.5v20H306v-20H331.5z M377,79.5h-71V60h71V79.5z"/>
<path d="M190.5,319.5c-5.521,0-10,4.479-10,10c0,5.52,4.479,10,10,10c5.52,0,10-4.48,10-10C200.5,323.979,196.02,319.5,190.5,319.5z
   "/>
<path d="M256,299.5c-5.522,0-10,4.478-10,10v10h-10c-5.522,0-10,4.478-10,10s4.478,10,10,10h20c5.522,0,10-4.478,10-10v-20
   C266,303.978,261.522,299.5,256,299.5z"/>
<path d="M256,267.5c-5.521,0-10,4.479-10,10c0,5.52,4.479,10,10,10c5.52,0,10-4.48,10-10C266,271.979,261.52,267.5,256,267.5z"/>
<path d="M256,203.5c-5.51,0-10,4.49-10,10v32c0,5.51,4.49,10,10,10c5.51,0,10-4.49,10-10v-32C266,207.99,261.51,203.5,256,203.5z"/>
<path d="M256,171.5c-5.51,0-10,4.49-10,10s4.49,10,10,10c5.51,0,10-4.49,10-10S261.51,171.5,256,171.5z"/>
<path d="M276,119.5h-20c-5.522,0-10,4.478-10,10v20c0,5.522,4.478,10,10,10s10-4.478,10-10v-10h10c5.522,0,10-4.478,10-10
   S281.522,119.5,276,119.5z"/>
<path d="M321.5,139.5c5.52,0,10-4.48,10-10c0-5.521-4.48-10-10-10c-5.521,0-10,4.479-10,10C311.5,135.02,315.979,139.5,321.5,139.5z
   "/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
  )
}

export default CALLROUTING