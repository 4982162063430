import React from 'react'

function Arrow() {
  return (
   
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1588 8.16616L14.5279 7.75021L13.9718 7.75021L1.32984 7.75021C1.10847 7.75021 0.899127 7.66756 0.747153 7.52499C0.595723 7.38293 0.513901 7.19379 0.513901 7.00021C0.513901 6.80664 0.595723 6.6175 0.747153 6.47543C0.899125 6.33286 1.10847 6.25021 1.32984 6.25021L13.9718 6.25021L14.5279 6.25021L14.1588 5.83427L10.2895 1.47427L10.2894 1.47419C10.2217 1.39799 10.1715 1.31091 10.1408 1.21826C10.1101 1.12565 10.0993 1.02869 10.1087 0.932693C10.1182 0.836681 10.1478 0.742704 10.1964 0.656107C10.2451 0.569479 10.3122 0.491629 10.3945 0.427589C10.5411 0.313636 10.7279 0.249855 10.9228 0.250214L10.9236 0.250213C11.0459 0.250026 11.1663 0.275147 11.276 0.323275C11.3857 0.37139 11.4814 0.441017 11.5571 0.526196L11.5571 0.526241L16.8796 6.51813C16.9056 6.5535 16.9289 6.59039 16.9494 6.62853L16.9788 6.68329L16.9959 6.69477L17.0139 6.73729C17.0493 6.8215 17.0678 6.91057 17.0688 7.00021C17.0681 7.0705 17.0565 7.14043 17.0345 7.20795C17.0033 7.23724 16.9738 7.27363 16.9524 7.31955C16.9364 7.35376 16.9282 7.38767 16.9239 7.41632C16.9101 7.43883 16.8953 7.46084 16.8796 7.4823L11.5571 13.4742L11.5569 13.4744C11.4201 13.6288 11.2203 13.7291 10.9997 13.7482C10.779 13.7673 10.5613 13.7029 10.3948 13.5731C10.2287 13.4436 10.1283 13.261 10.1093 13.0677C10.0903 12.8746 10.1534 12.6797 10.2896 12.526L14.1588 8.16616ZM17.0391 6.72422C17.0391 6.72422 17.0388 6.72394 17.0381 6.72337L17.0391 6.72422Z" fill="#303030" stroke="white" strokeWidth="0.5"/>
    </svg>
   
  )
}

export default Arrow