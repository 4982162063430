import React from 'react'

function Footer() {
    const handleNac = (num) => {
        setTimeout(() => {
          window.scrollTo(0, window.pageYOffset - 100);
        }, 1000);
      };
  return (
    <section className="container-fluid website_footer">
    <div className="container">
        <div className="row">
            <div className="col-lg-5 col-md-8 col-12">
                <h3>About Us</h3>
                <p className="p-s">
                Data communications leader India (DCL) is a multiproduct client call/chat support program organization centered on customer service, advertising, and IT solutions segments. DCL by its roots believes in being empathetic to clients and supplying services and products that deliver an incremental business outcome by assisting in the means of voice/chat sales support.
                </p>
                <div className="social_logo_con">
<a href='https://www.facebook.com/dclindiacompany' target="_blank">                
<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.1" cx="23" cy="23" r="23" fill="white"/>
<path d="M20.2922 24.696C20.2232 24.696 18.7052 24.696 18.0152 24.696C17.6472 24.696 17.5322 24.558 17.5322 24.213C17.5322 23.293 17.5322 22.35 17.5322 21.43C17.5322 21.062 17.6702 20.947 18.0152 20.947H20.2922C20.2922 20.878 20.2922 19.544 20.2922 18.923C20.2922 18.003 20.4532 17.129 20.9132 16.324C21.3962 15.496 22.0862 14.944 22.9602 14.622C23.5352 14.415 24.1102 14.323 24.7312 14.323H26.9852C27.3072 14.323 27.4452 14.461 27.4452 14.783V17.405C27.4452 17.727 27.3072 17.865 26.9852 17.865C26.3642 17.865 25.7432 17.865 25.1222 17.888C24.5012 17.888 24.1792 18.187 24.1792 18.831C24.1562 19.521 24.1792 20.188 24.1792 20.901H26.8472C27.2152 20.901 27.3532 21.039 27.3532 21.407V24.19C27.3532 24.558 27.2382 24.673 26.8472 24.673C26.0192 24.673 24.2482 24.673 24.1792 24.673V32.171C24.1792 32.562 24.0642 32.7 23.6502 32.7C22.6842 32.7 21.7412 32.7 20.7752 32.7C20.4302 32.7 20.2922 32.562 20.2922 32.217C20.2922 29.802 20.2922 24.765 20.2922 24.696Z" fill="white"/>
</svg>
</a>
<a href='https://www.linkedin.com/' target="_blank">
<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.1" cx="23" cy="23" r="23" fill="white"/>
<g clipPath="url(#clip0_1328_537)">
<path d="M32.9955 31.9999V31.9992H33V25.3977C33 22.1682 32.3047 19.6804 28.5292 19.6804C26.7142 19.6804 25.4962 20.6764 24.999 21.6207H24.9465V19.9819H21.3667V31.9992H25.0942V26.0487C25.0942 24.4819 25.3912 22.9669 27.3315 22.9669C29.2432 22.9669 29.2717 24.7549 29.2717 26.1492V31.9999H32.9955Z" fill="white"/>
<path d="M15.2969 19.9827H19.0289V31.9999H15.2969V19.9827Z" fill="white"/>
<path d="M17.1615 14C15.9683 14 15 14.9683 15 16.1615C15 17.3548 15.9683 18.3433 17.1615 18.3433C18.3547 18.3433 19.323 17.3548 19.323 16.1615C19.3222 14.9683 18.354 14 17.1615 14V14Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1328_537">
<rect width="18" height="18" fill="white" transform="translate(15 14)"/>
</clipPath>
</defs>
</svg>
</a>
<a href='https://www.instagram.com/' target="_blank">
<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.1" cx="23" cy="23" r="23" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.9586 14.0625C21.275 14.0625 19.9554 14.0625 18.9084 14.1759C17.84 14.2917 16.9743 14.5321 16.2352 15.0691C15.7877 15.3942 15.3942 15.7877 15.0691 16.2352C14.5321 16.9743 14.2917 17.84 14.1759 18.9084C14.0625 19.9554 14.0625 21.275 14.0625 22.9586V23.0414C14.0625 24.725 14.0625 26.0446 14.1759 27.0915C14.2917 28.16 14.5321 29.0256 15.0691 29.7648C15.3942 30.2122 15.7877 30.6058 16.2352 30.9308C16.9743 31.4679 17.84 31.7083 18.9084 31.8241C19.9554 31.9375 21.275 31.9375 22.9585 31.9375H23.0415C24.725 31.9375 26.0446 31.9375 27.0915 31.8241C28.16 31.7083 29.0256 31.4679 29.7648 30.9308C30.2122 30.6058 30.6058 30.2122 30.9308 29.7648C31.4679 29.0256 31.7083 28.16 31.8241 27.0915C31.9375 26.0446 31.9375 24.725 31.9375 23.0415V22.9585C31.9375 21.275 31.9375 19.9554 31.8241 18.9084C31.7083 17.84 31.4679 16.9743 30.9308 16.2352C30.6058 15.7877 30.2122 15.3942 29.7648 15.0691C29.0256 14.5321 28.16 14.2917 27.0915 14.1759C26.0446 14.0625 24.725 14.0625 23.0414 14.0625H22.9586ZM19.5625 23C19.5625 21.1015 21.1015 19.5625 23 19.5625C24.8985 19.5625 26.4375 21.1015 26.4375 23C26.4375 24.8985 24.8985 26.4375 23 26.4375C21.1015 26.4375 19.5625 24.8985 19.5625 23Z" fill="white"/>
<path d="M23 19C20.7909 19 19 20.7909 19 23C19 25.2091 20.7909 27 23 27C25.2091 27 27 25.2091 27 23C27 20.7909 25.2091 19 23 19Z" fill="#606080"/>
<path d="M23 21C21.8955 21 21 21.8955 21 23C21 24.1045 21.8955 25 23 25C24.1045 25 25 24.1045 25 23C25 21.8955 24.1045 21 23 21Z" fill="white"/>
<path d="M28 17C27.4477 17 27 17.4477 27 18C27 18.5523 27.4477 19 28 19C28.5523 19 29 18.5523 29 18C29 17.4477 28.5523 17 28 17Z" fill="#606080"/>
</svg>
</a>
<a href='https://twitter.com/' target="_blank">
<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.1" cx="23" cy="23" r="23" fill="white"/>
<g clipPath="url(#clip0_1328_536)">
<path d="M33 18.7988C32.2562 19.125 31.4637 19.3412 30.6375 19.4462C31.4875 18.9387 32.1363 18.1412 32.4413 17.18C31.6488 17.6525 30.7737 17.9863 29.8412 18.1725C29.0887 17.3713 28.0162 16.875 26.8462 16.875C24.5762 16.875 22.7487 18.7175 22.7487 20.9763C22.7487 21.3013 22.7762 21.6137 22.8438 21.9112C19.435 21.745 16.4188 20.1113 14.3925 17.6225C14.0387 18.2363 13.8313 18.9388 13.8313 19.695C13.8313 21.115 14.5625 22.3737 15.6525 23.1025C14.9937 23.09 14.3475 22.8988 13.8 22.5975C13.8 22.61 13.8 22.6262 13.8 22.6425C13.8 24.635 15.2212 26.29 17.085 26.6712C16.7512 26.7625 16.3875 26.8062 16.01 26.8062C15.7475 26.8062 15.4825 26.7912 15.2337 26.7362C15.765 28.36 17.2725 29.5537 19.065 29.5925C17.67 30.6837 15.8988 31.3412 13.9813 31.3412C13.645 31.3412 13.3225 31.3262 13 31.285C14.8162 32.4562 16.9688 33.125 19.29 33.125C26.835 33.125 30.96 26.875 30.96 21.4575C30.96 21.2762 30.9537 21.1013 30.945 20.9275C31.7587 20.35 32.4425 19.6288 33 18.7988Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1328_536">
<rect width="20" height="20" fill="white" transform="translate(13 15)"/>
</clipPath>
</defs>
</svg>
</a>


                </div>
            </div>
            <div className="col-lg-2 col-md-4  col-12">
                <h3>Quick Links</h3>
                <ul className="footer_link_list">
                <li onClick={()=>handleNac(1)}><a href="#about">About Us</a></li>

        <li onClick={()=>handleNac(2)}><a href="#why_dcl">Why DCL</a></li>
   
        <li onClick={()=>handleNac(3)}><a href="#faq">	FAQ</a></li>


       
      
                </ul>
            </div>
           
            <div className="col-lg-5 col-md-8 col-12">
                <h3>Contact Details</h3>
                <div className="contact_details_inner">

<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#0B486E" />
    <path
        d="M16.7 13.2007C17.2569 13.2007 17.791 13.422 18.1848 13.8158C18.5786 14.2096 18.7999 14.7437 18.7999 15.3006C18.7999 15.4863 18.8736 15.6643 19.0049 15.7956C19.1362 15.9268 19.3142 16.0006 19.4998 16.0006C19.6855 16.0006 19.8635 15.9268 19.9948 15.7956C20.1261 15.6643 20.1998 15.4863 20.1998 15.3006C20.1998 14.3724 19.8311 13.4822 19.1747 12.8259C18.5184 12.1695 17.6282 11.8008 16.7 11.8008C16.5143 11.8008 16.3363 11.8745 16.205 12.0058C16.0737 12.1371 16 12.3151 16 12.5007C16 12.6864 16.0737 12.8644 16.205 12.9957C16.3363 13.127 16.5143 13.2007 16.7 13.2007Z"
        fill="white" />
    <path
        d="M16.6996 10.4009C17.9991 10.4009 19.2454 10.9171 20.1643 11.836C21.0832 12.7549 21.5994 14.0012 21.5994 15.3007C21.5994 15.4863 21.6732 15.6644 21.8044 15.7956C21.9357 15.9269 22.1137 16.0007 22.2994 16.0007C22.485 16.0007 22.6631 15.9269 22.7943 15.7956C22.9256 15.6644 22.9993 15.4863 22.9993 15.3007C22.9993 13.6299 22.3356 12.0275 21.1542 10.8461C19.9728 9.66469 18.3704 9.00098 16.6996 9.00098C16.514 9.00098 16.336 9.07472 16.2047 9.20599C16.0734 9.33726 15.9997 9.5153 15.9997 9.70094C15.9997 9.88659 16.0734 10.0646 16.2047 10.1959C16.336 10.3272 16.514 10.4009 16.6996 10.4009ZM22.8244 18.7375C22.7859 18.6252 22.7194 18.5244 22.6312 18.4448C22.5431 18.3652 22.4361 18.3094 22.3204 18.2825L18.1206 17.3236C18.0066 17.2977 17.8879 17.3009 17.7754 17.3326C17.6629 17.3644 17.5602 17.4239 17.4766 17.5056C17.3786 17.5966 17.3716 17.6036 16.9166 18.4715C15.4069 17.7759 14.1973 16.5612 13.5078 15.0487C14.3967 14.6007 14.4037 14.6007 14.4947 14.4957C14.5764 14.4121 14.6359 14.3094 14.6677 14.1969C14.6995 14.0844 14.7026 13.9657 14.6767 13.8518L13.7178 9.70094C13.6909 9.58524 13.6351 9.47826 13.5555 9.3901C13.4759 9.30193 13.3752 9.23547 13.2628 9.19697C13.0993 9.13858 12.9305 9.09638 12.7588 9.07097C12.5819 9.02995 12.4014 9.00651 12.2198 9.00098C11.3659 9.00098 10.5469 9.34021 9.94307 9.94405C9.33923 10.5479 9 11.3669 9 12.2208C9.0037 15.0786 10.1406 17.8182 12.1613 19.839C14.1821 21.8597 16.9217 22.9966 19.7795 23.0003C20.2023 23.0003 20.621 22.917 21.0117 22.7552C21.4023 22.5934 21.7573 22.3562 22.0563 22.0573C22.3553 21.7583 22.5924 21.4033 22.7542 21.0127C22.9161 20.622 22.9993 20.2033 22.9993 19.7805C22.9996 19.6023 22.9855 19.4244 22.9573 19.2485C22.9279 19.0746 22.8834 18.9037 22.8244 18.7375Z"
        fill="white" />
</svg>

<div>
    <h4>Phone</h4>
    <p> <a href="tel:+91 7530082399"> +91 79047 67636</a></p>
   

</div>
</div>
<div className="contact_details_inner">

<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#0B486E" />
    <path
        d="M20.2 11H11.8C11.3226 11 10.8648 11.1975 10.5272 11.5492C10.1896 11.9008 10 12.3777 10 12.875V19.125C10 19.6223 10.1896 20.0992 10.5272 20.4508C10.8648 20.8025 11.3226 21 11.8 21H20.2C20.6774 21 21.1352 20.8025 21.4728 20.4508C21.8104 20.0992 22 19.6223 22 19.125V12.875C22 12.3777 21.8104 11.9008 21.4728 11.5492C21.1352 11.1975 20.6774 11 20.2 11ZM20.2 12.25L16.3 15.0438C16.2088 15.0986 16.1053 15.1275 16 15.1275C15.8947 15.1275 15.7912 15.0986 15.7 15.0438L11.8 12.25H20.2Z"
        fill="white" />
</svg>

<div>
    <h4>Email</h4>
    <p> <a href="mailto:samthepaulofficial@gmail.com"> dclindia@datacommunicationsleader.com</a></p>

</div>
</div>
                <div className="contact_details_inner">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill="#0B486F" />
                        <path
                            d="M17.223 22.9184C19.0674 21.2951 22.3489 17.9786 22.3489 14.9352C22.3489 13.2513 21.68 11.6363 20.4893 10.4456C19.2986 9.25488 17.6836 8.58594 15.9997 8.58594C14.3157 8.58594 12.7008 9.25488 11.5101 10.4456C10.3193 11.6363 9.65039 13.2513 9.65039 14.9352C9.65039 17.9786 12.9309 21.2951 14.7764 22.9184C15.113 23.2188 15.5484 23.3849 15.9997 23.3849C16.4509 23.3849 16.8863 23.2188 17.223 22.9184ZM13.8832 14.9352C13.8832 14.3739 14.1062 13.8356 14.5031 13.4387C14.9 13.0418 15.4384 12.8188 15.9997 12.8188C16.561 12.8188 17.0993 13.0418 17.4962 13.4387C17.8931 13.8356 18.1161 14.3739 18.1161 14.9352C18.1161 15.4965 17.8931 16.0348 17.4962 16.4318C17.0993 16.8287 16.561 17.0516 15.9997 17.0516C15.4384 17.0516 14.9 16.8287 14.5031 16.4318C14.1062 16.0348 13.8832 15.4965 13.8832 14.9352Z"
                            fill="white" />
                    </svg>

                    <div>
                        <h4>Address</h4>
                        <p className="p-s">No 9, Om Ganesh Nagar 3rd Cross E,<br/> Vadavalli, Tamil Nadu 641041</p>

                    </div>
                </div>

            </div>
          
            <div className="footer_bottom_row">
                
            </div>

        </div>

        <a style={{textAlign:'center',display:'block',margin:'20px auto 0'}}>2022 Data Communications Leader. All Rights Reserved</a>
    </div>
</section>
  )
}

export default Footer