import React from 'react'

function Star() {
  return (
    <div>        
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.16393 1.61804C8.72064 0.527163 10.2794 0.527163 10.8361 1.61804L12.6005 5.07543C12.8184 5.50251 13.2274 5.79966 13.701 5.87497L17.5344 6.48464C18.7439 6.67701 19.2256 8.15944 18.3601 9.026L15.6172 11.7725C15.2784 12.1117 15.1221 12.5925 15.1968 13.0661L15.8016 16.9003C15.9924 18.1101 14.7314 19.0263 13.6398 18.471L10.1801 16.711C9.75277 16.4936 9.24723 16.4936 8.81987 16.711L5.36022 18.471C4.26863 19.0263 3.0076 18.1101 3.19841 16.9003L3.80317 13.0661C3.87787 12.5925 3.72165 12.1117 3.38282 11.7725L0.639884 9.026C-0.225568 8.15944 0.256104 6.67701 1.46562 6.48464L5.29903 5.87497C5.77256 5.79966 6.18155 5.50251 6.3995 5.07543L8.16393 1.61804Z" fill="#FCD03B"/>
        </svg>
    </div>
  )
}

export default Star