import React from 'react'

function AUTOATTENDANT() {
  return (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 511.999 511.999" style={{enableBackground:'new 0 0 511.999 511.999'}} xmlSpace="preserve">
<path style={{fill:'#ED7161'}} d="M360.766,144.856c0.005-0.187,0.007-113.279,0.007-113.279c0-11.909-9.654-21.565-21.565-21.565
	H172.816c-11.909,0-21.565,9.654-21.565,21.565v114.716c0,11.909,9.654,21.565,21.565,21.565h40.251v34.571l54.799-34.571h22.62"/>
<path style={{fill:'#26CFAD'}} d="M480.435,146.855H314.043c-11.909,0-21.565,9.654-21.565,21.565v114.716
	c0,11.909,9.654,21.565,21.565,21.565h40.251v34.571l54.799-34.571h71.343c11.909,0,21.565-9.654,21.565-21.565V168.419
	C501.999,156.51,492.344,146.855,480.435,146.855z"/>
<path d="M480.435,136.856H370.768c0.002-11.456,0.004-38.979,0.006-105.28c0-17.404-14.16-31.563-31.564-31.563H172.816
	c-17.404,0-31.564,14.159-31.564,31.563v114.716c0,17.404,14.16,31.564,31.564,31.564h30.251v24.572
	c0,3.643,1.981,6.998,5.171,8.757c1.506,0.83,3.168,1.243,4.829,1.243c1.856,0,3.71-0.517,5.336-1.542l52.353-33.028h11.722v105.279
	c0,17.404,14.159,31.564,31.563,31.564h30.251v24.571c0,3.643,1.981,6.998,5.171,8.757c1.506,0.83,3.168,1.243,4.829,1.243
	c1.856,0,3.709-0.516,5.336-1.542l52.354-33.028h68.453c17.404,0,31.563-14.16,31.563-31.564V168.419
	C511.999,151.015,497.839,136.856,480.435,136.856z M267.865,157.857c-1.889,0-3.738,0.535-5.335,1.542l-39.464,24.897v-16.439
	c0-5.523-4.477-9.999-9.999-9.999h-40.251c-6.377,0-11.565-5.188-11.565-11.565V31.576c0-6.377,5.188-11.564,11.565-11.564h166.392
	c6.377,0,11.565,5.188,11.565,11.564c0,0.942-0.002,79.65-0.005,105.28h-36.727c-13.701,0-25.392,8.776-29.745,21.001H267.865z
	 M492,283.135c0,6.377-5.188,11.565-11.564,11.565h-71.343c-1.889,0-3.738,0.535-5.335,1.542l-39.464,24.896v-16.439
	c0-5.523-4.477-9.999-9.999-9.999h-40.253c-6.377,0-11.564-5.188-11.564-11.565V168.419c0-6.377,5.188-11.564,11.564-11.564h166.393
	c6.377,0,11.564,5.188,11.564,11.564v114.716H492z"/>
<path d="M366.005,243.249c0-0.326,0.108-0.761,0.217-1.195l20.207-66.267c1.195-3.803,6.084-5.649,11.081-5.649
	s9.885,1.847,11.081,5.649l20.315,66.267c0.108,0.435,0.217,0.869,0.217,1.195c0,4.02-6.192,6.953-10.863,6.953
	c-2.716,0-4.889-0.869-5.541-3.15l-3.694-13.579h-22.923l-3.693,13.579c-0.652,2.281-2.825,3.15-5.541,3.15
	C372.196,250.202,366.005,247.268,366.005,243.249z M405.44,220.435l-7.931-29.114l-7.931,29.114H405.44z"/>
<g>
	<path style={{fill:'#9BE0FF'}} d="M149.929,271.994c9.848-9.848,11.873-25.158,4.924-37.229l-49.068-89.656
		c-4.83-8.391-13.106-13.93-22.704-15.2c-9.424-1.247-18.696,1.818-25.511,8.418l81.677,144.349L149.929,271.994z"/>
	<path style={{fill:'#9BE0FF'}} d="M378.707,425.536c-1.269-9.597-6.81-17.874-15.199-22.704l-89.656-49.067
		c-12.069-6.951-27.38-4.926-37.229,4.924l-10.681,10.681l144.349,81.677C376.889,444.233,379.954,434.966,378.707,425.536z"/>
</g>
<path style={{fill:'#6680C0'}} d="M225.94,369.37l-8.163,8.163c-4.692,4.691-12.108,5.106-17.254,0.967
	c-14.83-11.933-23.418-19.052-37.387-33.021c-13.968-13.969-21.087-22.557-33.021-37.387c-4.14-5.145-3.725-12.563,0.967-17.254
	l8.163-8.162l-81.677-144.35c-0.122,0.118-0.243,0.237-0.364,0.358L28.81,167.078c-20.092,20.092-26.496,50.677-7.106,97.044
	c18.544,44.344,47.121,87.13,91.391,131.4s87.055,72.847,131.4,91.391c22.75,9.513,39.229,15.073,56.481,15.073
	c17.907,0,30.328-11.945,40.562-22.18l28.394-28.394c0.12-0.12,0.239-0.242,0.358-0.364L225.94,369.37z"/>
<path d="M388.621,424.225L388.621,424.225c-1.68-12.707-9.015-23.664-20.124-30.059c-0.063-0.036-0.125-0.071-0.188-0.105
	l-89.567-49.019c-15.965-9.134-36.175-6.437-49.189,6.577L218.871,362.3l-8.162,8.162c-1.074,1.073-2.758,1.179-3.915,0.248
	c-15.249-12.27-23.318-19.034-36.584-32.301c-13.269-13.27-20.033-21.339-32.302-36.585c-0.933-1.159-0.827-2.841,0.247-3.914
	l8.163-8.163c0.008-0.008,0.015-0.016,0.023-0.023L157,279.065l0,0c13.011-13.012,15.709-33.222,6.578-49.187l-49.021-89.569
	c-0.034-0.063-0.069-0.126-0.105-0.188c-6.395-11.108-17.351-18.444-30.059-20.124c-12.45-1.646-24.763,2.415-33.778,11.146
	c-0.01,0.009-0.019,0.019-0.029,0.028c-0.151,0.146-0.301,0.293-0.45,0.442l-28.394,28.394
	c-25.459,25.46-28.574,61.787-9.26,107.972c19.863,47.497,50.461,91.529,93.545,134.613s87.115,73.683,134.613,93.546
	c24.001,10.037,41.568,15.848,60.339,15.848c22.525,0,37.635-15.11,47.633-25.109l28.379-28.38c0.156-0.155,0.309-0.312,0.462-0.469
	c0.008-0.008,0.015-0.015,0.023-0.023C386.204,448.988,390.267,436.677,388.621,424.225z M243.693,365.759
	c6.659-6.661,17.009-8.03,25.169-3.33c0.063,0.036,0.126,0.071,0.189,0.106l89.552,49.011c5.625,3.272,9.337,8.842,10.191,15.299
	l0,0c0.504,3.811-0.039,7.596-1.514,11.007L242.31,367.141L243.693,365.759z M81.769,139.822c6.457,0.854,12.027,4.566,15.3,10.19
	l49.012,89.553c0.034,0.063,0.069,0.126,0.105,0.188c4.698,8.162,3.33,18.511-3.329,25.169l-1.385,1.385L70.762,141.336
	C74.174,139.86,77.956,139.317,81.769,139.822z M334.468,472.735c-10.532,10.532-20.322,19.25-33.491,19.25
	c-15.414,0-30.967-5.243-52.623-14.299c-45.049-18.839-86.979-48.029-128.187-89.237c-41.208-41.207-70.397-83.137-89.236-128.186
	c-16.104-38.51-14.484-66.678,4.952-86.114L55.34,154.69l71.459,126.291l-2.784,2.784c-8.311,8.31-9.037,21.461-1.687,30.594
	c11.36,14.116,19.067,23.514,33.741,38.188c14.671,14.672,24.07,22.381,38.19,33.742c9.13,7.347,22.282,6.621,30.591-1.687
	l2.785-2.785l126.291,71.458L334.468,472.735z"/>
<path d="M250.743,119.985v-6.301c-12.385-1.521-21.402-8.8-21.402-25.313V59.583c0-18.794,11.624-25.639,26.617-25.639
	c14.991,0,26.725,6.845,26.725,25.639v28.789c0,16.513-9.126,23.791-21.51,25.313v6.301c0,3.477-2.607,5.215-5.215,5.215
	C253.351,125.2,250.743,123.461,250.743,119.985z M265.734,59.583c0-7.388-3.693-10.863-9.776-10.863
	c-6.084,0-9.668,3.477-9.668,10.863v28.789c0,4.998,1.521,8.148,4.455,9.777v-4.78c0-3.585,2.607-5.106,5.215-5.106
	c2.607,0,5.215,1.521,5.215,5.106v4.78c2.824-1.63,4.563-4.78,4.563-9.777V59.583H265.734z"/>
<path d="M466.311,279.997c-0.65,0-1.31-0.07-1.95-0.2c-0.64-0.12-1.27-0.32-1.87-0.57c-0.61-0.25-1.19-0.56-1.73-0.92
	c-0.55-0.36-1.06-0.78-1.52-1.24c-1.86-1.86-2.93-4.44-2.93-7.07c0-0.65,0.07-1.31,0.2-1.95c0.12-0.64,0.32-1.27,0.57-1.88
	c0.25-0.6,0.56-1.18,0.92-1.72c0.36-0.55,0.78-1.06,1.24-1.52c0.46-0.46,0.97-0.88,1.52-1.24c0.54-0.37,1.12-0.67,1.73-0.92
	c0.6-0.25,1.23-0.45,1.87-0.57c3.24-0.66,6.7,0.41,9.02,2.73c0.46,0.46,0.88,0.97,1.24,1.52c0.36,0.54,0.67,1.12,0.92,1.72
	c0.25,0.61,0.45,1.24,0.58,1.88c0.13,0.64,0.19,1.3,0.19,1.95c0,2.63-1.07,5.21-2.93,7.07
	C471.521,278.927,468.941,279.997,466.311,279.997z"/>
<path d="M429.314,279.996H329.009c-5.523,0-9.999-4.477-9.999-9.999s4.477-9.999,9.999-9.999h100.306
	c5.523,0,9.999,4.477,9.999,9.999S434.837,279.996,429.314,279.996z"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
  )
}

export default AUTOATTENDANT